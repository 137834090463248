<header (window:beforeunload)="doBeforeUnload()" class="main-header">

    <!-- Sidebar toggle button-->

    <!-- Logo -->
    <a id="logo" [routerLink]="(this.roleService.objRole.isUserAuditorGRID || this.roleService.objRole.isUserAuditorLevel1 || this.roleService.objRole.isUserAuditorLevel2 || this.roleService.objRole.isUserAuditorLevel3) ? ['/user-audit'] : ['/dashboard']" class="logo">
        <!-- logo for regular state and mobile devices -->
        <span class="logo-lg0"><img src="../../../../assets/images/logo.svg" alt=""></span>
    </a>
    <!-- <span class="header-info-label"> {{authService.pageTitle}}</span> -->

    <ng-marquee class="marqee" [stopOnHover]="true" speed="drowsy" *ngIf="tickerTapMessageService.scheduleMessages.length">
        <mark class="message-class" [innerHTML]="msg['message']" *ngFor="let msg of tickerTapMessageService.scheduleMessages">
        </mark>
    </ng-marquee>

    <!-- Header Navbar: style can be found in header.less -->
    <nav class="navbar navbar-static-top">
        <div class="navbar-custom-menu">
            <ul class="nav navbar-nav">
                <!-- <li id="notification" class="notification"><a title="Notifications" href="javascript:void()"><span>&nbsp;</span><i class="align-centre"><img src="../../../../assets/images/notifiacation-icon.svg" alt="Notification"></i></a></li> -->
                <li id="users" class="dropdown user user-menu ">
                    <a href="#" class="dropdown-toggle align-items-center" data-toggle="dropdown">
                        <span class="user-img">
                <img class="user-image" src="../../assets/images/welcome-user.svg" height="30" width="30" />
              </span>

                        <!-- <span class="hidden-xs">John Smith</span> -->
                    </a>
                    <ul class="dropdown-menu user-drop-box">
                        <li class="welcome">
                            <span>Welcome <b>{{roleService.userName}}</b></span>
                        </li>
                        <!-- <li class="user-body">
                            <a id="edit-profile" href="javascript:void(0)" class="disabled-li"><i class="m-r-5"><img
                      src="../../../../assets/images/profile-icon-white.svg" alt="Profile"></i> Profile</a>
                        </li>
                        <li class="user-body">
                            <a id="edit-account" href="javascript:void(0)" class="disabled-li"><i class="m-r-5"><img
                      src="../../../../assets/images/edit-icon-white.svg" alt="Edit Account"></i> Edit Account</a>
                        </li> -->
                        <li class="user-body">
                            <a id="edit-account" href="javascript:void(0)" (click)="Logout()"><i class="m-r-5"><img
                      src="../../../../assets/images/logout-icon.svg" alt="Logout"></i> Sign out</a>
                        </li>

                    </ul>
                </li>
                <!-- <li id="logout" class="notification"><a onclick="return false;" (click)="Logout()" title="Logout" href="javascript:void()"><i class="align-centre"><img src="../../../../assets/images/logout-icon.svg" alt="Logout"></i></a></li> -->
            </ul>
        </div>
    </nav>

</header>
<div class="notification-alert" *ngIf="alerts.length">
    <div *ngFor="let msg of alerts">
        <img src="../../../../assets/images/notifiacation-alert-icon.svg" alt=""> 
        {{msg.message}}         
    </div>    
</div>
<ng-template #confirmDialogTemplate>
    <app-confirm-dialog title="CONFIRMATION" successTitle="Yes" cancelTitle="No"
        message="{{dialogTexts.message}}"
        (onConfirm)="onConfirm()" (onCancel)="onCancel()">
    </app-confirm-dialog>
</ng-template>