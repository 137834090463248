<aside class="main-sidebar">
    <a href="#" class="sidebar-toggle" data-toggle="offcanvas" role="button">
        <i class="collapse-icon"><img src="../../../../assets/images/menu-close.svg" alt=""></i>
        <i class="expand"><img src="../../../../assets/images/menu-close.svg" alt=""></i>
    </a>
    <section class="sidebar-custom-menu">
        <ul class="sidebar-menu tooltip-example">

            <li *ngIf="menus[0].isdisplay" appTooltip="Org Structure" placement="right" delay="100" [ngClass]="{'active': (authService.currentRoute == 'dashboard' || authService.currentRoute == 'entity')}">
                <a id="menu-entity" [routerLink]="['/entity']" href="#" onclick="return false;"><i class="icon-sidebar icon-users"></i><span>Org Structure</span></a>
            </li>
            <li *ngIf="menus[0].isdisplay" appTooltip="Machines" placement="right" delay="100" [ngClass]="{'active': (authService.currentRoute == 'machine')}">
                <a id="menu-machine" [routerLink]="['/machine']" href="#" onclick="return false;"><i class="icon-sidebar icon-machine"></i><span>Machines</span></a>
            </li>
            <li *ngIf="menus[3].isdisplay" [ngClass]="{'active': (authService.currentRoute == 'sales')}" appTooltip="Sales" placement="right" delay="100">
                <a id="menu-sales" href="#" [routerLink]="['/sales']" onclick="return false;"><i class="icon-sidebar icon-sales"></i><span>Sales</span></a>
            </li>
            <li *ngIf="roleService.objRole.isSystemAdmin || roleService.objRole.isGlobalMarketAdmin || roleService.objRole.isMarketAdmin || roleService.objRole.isCustomerAdmin || roleService.objRole.isLevel2Admin || roleService.objRole.isLevel3Admin" appTooltip="Users" placement="right" delay="100" [ngClass]="{'active': (authService.currentRoute == 'user')}">
                <a id="menu-user" [routerLink]="['/user']" href="#" onclick="return false;"><i class="icon-sidebar icon-facility"></i><span>Users</span></a>
            </li>
            <li *ngIf="(roleService.objRole.isSystemAdmin || roleService.objRole.isGlobalMarketAdmin) && !drinkSetupFT" appTooltip="Price Card Templates" placement="right" delay="100" [ngClass]="{'active': (authService.currentRoute == 'price-card-template')}">
                <a id="menu-price-card-template" [routerLink]="['/price-card-template']" onclick="return false;"><i class="icon-sidebar icon-drins-g-price"></i><span>Price Card Templates</span></a>
            </li>
            <li *ngIf="(roleService.objRole.isSystemAdmin || roleService.objRole.isGlobalMarketAdmin) && drinkSetupFT" appTooltip="Drinks Setup" placement="right" delay="100" [ngClass]="{'active': (authService.currentRoute == 'drinks-setup')}">
                <a id="menu-drinks-setup" [routerLink]="['/drinks-setup']" onclick="return false;"><i class="icon-sidebar icon-drins-g-price"></i><span>Drinks Setup</span></a>
            </li>
            <li *ngIf="menus[2].isdisplay" appTooltip="Price Card" placement="right" delay="100" [ngClass]="{'active': (authService.currentRoute == 'price-card')}">
                <a id="menu-price-card" [routerLink]="['/price-card']" href="#" onclick="return false;"><i class="icon-sidebar icon-price-card"></i><span>Price Card</span></a>
            </li>
            <li *ngIf="menus[6].isdisplay" appTooltip="Alarm Template" placement="right" delay="100" [ngClass]="{'active': (authService.currentRoute == 'alarm-template')}">
                <a id="menu-alarm-template" [routerLink]="['/alarm-template']" href="#" onclick="return false;"><i class="icon-sidebar icon-event-alarms"></i><span>Alarm Template</span></a>
            </li>
            <li *ngIf="(menus[7].isdisplay)" appTooltip="Alarms" placement="right" delay="100" [ngClass]="{'active': (authService.currentRoute == 'alarms')}">
                <a id="menu-alarms" [routerLink]="['/alarms']" href="#" onclick="return false;"><i class="icon-sidebar icon-alarms"></i><span>Alarms</span></a>
            </li>
            <li *ngIf="menus[10].isdisplay || roleService.objRole.isSystemAdmin" appTooltip="Configuration" placement="right" delay="100" [ngClass]="{'active': (authService.currentRoute == 'configuration')}">
                <a id="menu-configuration" [routerLink]="['/configuration']" href="#" onclick="return false;"><i class="icon-sidebar icon-configuration"></i><span>Configuration</span></a>
            </li>
            <li *ngIf="menus[9].isdisplay" appTooltip="User Audit" placement="right" delay="100" [ngClass]="{'active': (authService.currentRoute == 'user-audit')}">
                <a id="menu-user-audit" [routerLink]="['/user-audit']" onclick="return false;"><i class="icon-sidebar icon-user-audit"></i><span>User Audit</span></a>
            </li>
            <li *ngIf="menus[5].isdisplay" appTooltip="User Verification Review" placement="right" delay="100" [ngClass]="{'active': (authService.currentRoute == 'user-verification-review')}">
                <a id="menu-user-verification-review" [routerLink]="['/user-verification-review']" onclick="return false;"><i class="icon-sidebar icon-users-verification"></i><span>User Verification Review</span></a>
            </li>           
            <li *ngIf="menus[4].isdisplay" appTooltip="Notifications" placement="right" delay="100" [ngClass]="{'active': (authService.currentRoute == 'notifications')}">
                <a id="menu-notification" *ngIf="roleService.objRole.isUserAuditorGRIDR" [routerLink]="['/notifications']" href="#" onclick="return false;"><i class="icon-sidebar icon-notification"></i><span>Notifications</span></a>
                <a id="menu-notification" *ngIf="!roleService.objRole.isUserAuditorGRIDR"  [routerLink]="['/notifications']" href="#" onclick="return false;"><i class="icon-sidebar icon-notification"></i><span>Notifications ({{notificationCount}})</span></a>
            </li>
            <li *ngIf="menus[8].isdisplay" appTooltip="Audit Logs" placement="right" delay="100" [ngClass]="{'active': (authService.currentRoute == 'audit-logs')}">
                <a id="menu-audit-logs" [routerLink]="['/audit-logs']" href="#" onclick="return false;"><i class="icon-sidebar icon-auditlogs"></i><span>Audit Logs</span></a>
            </li>                       
            <li *ngIf="(roleService.objRole.isSystemAdmin || roleService.objRole.isMarketAdmin || roleService.objRole.isLevel2Admin || roleService.objRole.isLevel3Admin) && remoteUpdateFT" appTooltip="Remote Update Management" placement="right" delay="100" [ngClass]="{'active': (authService.currentRoute == 'task-management-overview')}">
                <a id="menu-remote-update-tasks" [routerLink]="['/task-management-overview']" href="#" onclick="return false;"><i class="icon-remotetasks"></i><span>Remote Management</span></a>
            </li>                    
        </ul>
        
    </section>
</aside>
<ng-template #infoAlertTemplate>
    <app-alert-confirm-dialog [alertConfirmDataModel]="alertConfirmDataModel" (onConfirm)="onConfirmOk()">
    </app-alert-confirm-dialog>
</ng-template>
