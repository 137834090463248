import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { HotjarService } from './services/hotjar/hotjar.service';

declare var $: any;
declare var $: any;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
	title = 'Costa Coffee';
	loginURL: string = '';
	isLoginURL: boolean = false;
	previousUrl: string;

	constructor(public authService: AuthService,  public router: Router,
		@Inject(DOCUMENT) private document: any, private hotjarService: HotjarService) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				if (window.innerWidth < 1200) {
					if (document.body.classList.contains('sidebar-open')) {
						document.body.classList.add('sidebar-close');
						document.body.classList.remove('sidebar-open');
					}
				}
			}
			this.pageRoutingEvent(event);
		});
		let routesArray = ['login', 'error', '/callback', '/'];
		this.router.events.subscribe((event: RouterEvent) => {
			let tempUrl:any = (this.router.url).split('/');
			let cUrl = tempUrl[1].split('?');
			this.authService.currentRoute = cUrl[0];
			if(this.authService.currentRoute == '' || this.authService.currentRoute == 'login'){
				$('html').addClass('hide-scroll');
			}else{
				$('html').removeClass('hide-scroll');
			}
			if(this.authService.currentRoute.indexOf('notifications') != -1){
				this.document.body.classList.add('abc');
			} else {
				this.document.body.classList.remove('abc');
			}
			if (event instanceof NavigationStart) {
				let currentUrlSlug = event.url.slice(1);
				this.previousUrl = currentUrlSlug;
				localStorage.setItem('previousUrl',this.router.url);
			}

			if (event instanceof NavigationEnd) {
				this.loginURL = event.url;
				const newRoute = event.url.split('/')[1] || '/';
        if (routesArray.indexOf(newRoute) > -1) {
					this.isLoginURL = false;
				} else {
					this.isLoginURL = true;
				}

			}
		});

	}

	ngOnInit(): void {
		// Hotjar will be initialized automatically by the Hotjar service
	  }

	pageRoutingEvent(event: Event): void {
		if (event instanceof NavigationStart) {
			$('[data-toggle="tooltip"]').tooltip("hide");
		}
		if (event instanceof NavigationEnd) {
			$('[data-toggle="tooltip"]').tooltip("hide");
		}
		if (event instanceof NavigationError) {
			$('[data-toggle="tooltip"]').tooltip("hide");
		}
		if (event instanceof NavigationCancel) {
			$('[data-toggle="tooltip"]').tooltip("hide");
		}
	}
}
