<section class="content-wrapper">
    <div class="content">
            <section class="content-header m-b-0">
                    <div class="d-flex">
                        <a id="menu" href="#" class="sidebar-toggle" data-toggle="offcanvas" role="button" title="Menu"> <i class="collapse-icon"><img src="../../../../assets/images/more-nav-icon.png" alt=""></i> <i class="expand"><img src="../../../../assets/images/more-nav-icon.png" alt=""></i> </a>
                        <div class="breadcrumb-title">
                            <h1>Page Not Found</h1>
                            <div class="breadcrumb">
                                <ol>
                                    <li>
                                        <a id="dashboard" title="Home" [routerLink]="['/dashboard']"><img src="../../../assets/images/breadcrumb-home-icon.svg" alt=""></a>
                                    </li>
                                    <li><span>Page Not Found</span></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    
                </section>
    <!-- <div class="unauthorized">
            <div class="title">
            <img src="../../../../assets/images/error-icon.svg" alt="">
            <span>404 Page Not Found</span>
    </div>
</div> -->

<div class="page404">
    <h2>404</h2>
    <h3>Page not found.</h3>
    <!-- <div class="divider"></div>
    <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
        Please try the navigation or search bar above to find what you’re looking for.</p> -->
        <div class="button-row m-t-20">
            <button type="button" mat-flat-button  color="primary" title="BACK TO HOME PAGE" (click)="gotoHomePage()">BACK TO HOME PAGE</button>
            
        </div>
    
</div>

</div>
</section>