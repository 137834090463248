<section class="content-wrapper">
    <div class="content">
        <section class="content-header">
            <div class="d-flex">
                <a href="#" id="sidebar-toggle" class="sidebar-toggle" data-toggle="offcanvas" role="button"
                    title="Menu"> <i class="collapse-icon"><img src="../../../../assets/images/more-nav-icon.png"
                            alt=""></i> <i class="expand"><img src="../../../../assets/images/more-nav-icon.png"
                            alt=""></i> </a>
                <div class="breadcrumb-title">
                    <h1>User Audit</h1>
                    <div class="breadcrumb">
                        <ol>
                            <li>
                                <a id="breadcrumb-home" [routerLink]="(this.roleService.objRole.isUserAuditorGRID || this.roleService.objRole.isUserAuditorLevel1 || this.roleService.objRole.isUserAuditorLevel2 || this.roleService.objRole.isUserAuditorLevel3) ? ['/user-audit'] : ['/dashboard']" title="Home"><img
                                        src="../../../assets/images/breadcrumb-home-icon.svg" alt=""></a>
                            </li>
                            <li><span>User Audit</span></li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-action">
                <form *ngIf="userAuditList.length">
                    <div class="p-l-10">
                        <button id="btn-submit" title="Submit" type="button" (click)="submtiAudits()" mat-flat-button color="primary" class="create m-l-10"><img src="../../../assets/images/complete.svg"  alt="">Submit</button>
                    </div>                    
                </form>
            </div>
        </section> 
        <mat-card *ngIf="isPageDataLoad && isVisibleStartButton">
            <ul id="ul-configuration" class="configurations-dashboard">
                <li id="li-configuration-ongredients">
                    <a tabindex="1" class="audit" id="configuration-ingredients" (click)="startaudit()" href="#" onclick="return false;" [ngStyle]="{'pointer-events': (isVisibleStartButton && isAuditCycleCompleted) ? 'none' : '' }"><span>&nbsp;</span> Start Audit</a>                    
                </li>
            </ul>
            
         </mat-card>       
         <div *ngIf="!isVisibleStartButton" class="table-container alarm-table">
            <table id="table-user" mat-table [dataSource]="userAuditList" matSort>               
                <ng-container matColumnDef="firstName">
                    <th class="w150" mat-header-cell *matHeaderCellDef>First Name</th>
                    <td title="{{element.firstName}}" class="mxw150" mat-cell *matCellDef="let element">
                        <div class="text-overflow-dynamic-container">
                            <div class="text-overflow-dynamic-ellipsis">
                                {{element.firstName}}
                            </div>    
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="lastName">
                    <th class="w150" mat-header-cell *matHeaderCellDef>Last Name
                    </th>
                    <td title="{{element.lastName}}" class="mxw150" mat-cell *matCellDef="let element">
                        <div class="text-overflow-dynamic-container">
                            <div class="text-overflow-dynamic-ellipsis">
                                {{element.lastName}}
                            </div>    
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th class="w250" mat-header-cell *matHeaderCellDef>Email Address
                    </th>
                    <td title="{{element.email}}" class="mxw250" mat-cell *matCellDef="let element">
                        <div class="text-overflow-dynamic-container">
                            <div class="text-overflow-dynamic-ellipsis">
                                {{element.email}}
                            </div>    
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="companyName">
                    <th class="w150" mat-header-cell *matHeaderCellDef>Company Name
                    </th>
                    <td title="{{element.companyName}}" class="mxw150" mat-cell *matCellDef="let element">
                        <div class="text-overflow-dynamic-container">
                            <div class="text-overflow-dynamic-ellipsis">
                                {{(element.companyName)?element.companyName:'NA'}}
                            </div>    
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="userRole">
                    <th class="w150" mat-header-cell *matHeaderCellDef>User Role</th>                    
                    <td mat-cell *matCellDef="let element">
                                {{element.userRole}}                            
                    </td>
                </ng-container>
                <ng-container matColumnDef="marketName">
                    <th class="w150" mat-header-cell *matHeaderCellDef>Market Name
                    </th>
                    <td title="{{element.marketName}}" class="mxw150" mat-cell *matCellDef="let element">
                        <div class="text-overflow-dynamic-container">
                            <div class="text-overflow-dynamic-ellipsis">
                                {{(element.marketName)?element.marketName:'NA'}}
                            </div>    
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="entityLevel">
                    <th class="w150" mat-header-cell *matHeaderCellDef>Entity level</th>                   
                    <td title="{{(element.entityLevel)?element.entityLevel:'NA'}}" mat-cell *matCellDef="let element">
                        {{(element.entityLevel)?element.entityLevel:'NA'}}                        
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="accountStatus">
                    <th class="w100" mat-header-cell *matHeaderCellDef>Account Status</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="{{(element.accountStatus)?'active':'inactive'}}">{{(element.accountStatus)?'Active':'Inactive'}}</span>
                    </td>                    
                </ng-container>
                <ng-container matColumnDef="accountCreated">
                    <th class="w140" mat-header-cell *matHeaderCellDef mat-sort-header id="sort-accountCreated">Date account was created</th>
                    <td mat-cell *matCellDef="let element"> {{element.accountCreated | date:'dd-MM-yyyy'}} </td>
                </ng-container>
              
                <ng-container matColumnDef="lastSignInDateTime">
                    <th class="w320" mat-header-cell *matHeaderCellDef>Date / Time the user last logged in</th>
                    <td mat-cell *matCellDef="let element"> 
                        <span *ngIf="element.lastSignInDateTime"> 
                            {{element['lastSignInDateTime'] | date:'dd-MM-yyyy'}} {{element['lastSignInDateTime'] | date:'HH:mm:ss'}} +00:00 UTC 
                        </span>
                        <span *ngIf="!element.lastSignInDateTime"> NA</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="auditorComment" stickyEnd>
                    <th class="w350" mat-header-cell *matHeaderCellDef>Auditor Comment
                    </th>
                    <td mat-cell *matCellDef="let element;let i = index">
                        <mat-form-field class="example-full-width mb-0"> 
                            <textarea (input)="onCommentChange(i)" (keyup)="adjustCommentBoxHeight($event)" [disabled]="element.isProcessed" tabIndex="1"  [(ngModel)]="element.auditorComment" [trim]="roleService.trimOption" id="Text-Area-description" matInput placeholder="{{(element.isProcessed)?'NA':'Enter Comment'}}" #auditorCommentData="ngModel"></textarea>
                            <mat-error *ngIf="element.auditorComment && element.auditorComment.length > 250">
                                Error
                            </mat-error>                                      
                            <mat-icon *ngIf="element.auditorComment &&  element.auditorComment.length > 250" matSuffix class="custom-error-icon"
                                matTooltipHideDelay="1500" matTooltip="Maximum characters limit of 250">
                                info
                            </mat-icon>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="isApproved" stickyEnd>
                    <th class="w100" mat-header-cell *matHeaderCellDef>Is Approved
                    </th>
                    <td class="" mat-cell *matCellDef="let element">
                        <mat-checkbox
                            tabindex="1"
                            class="example-margin"
                            [(ngModel)]="element.isApproved"
                            [disabled]="element.isAuditProcessDue || element.isProcessed || (element.auditorComment != null && element.auditorComment != '')">    
                        </mat-checkbox>                            
                    </td>
                </ng-container>               
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                 <!-- Footer for no record found -->
                <ng-container matColumnDef="noData">
                <!-- Set colspan as per your grid number of column -->
                    <td class="no_record_found" mat-footer-cell *matFooterCellDef colspan="11">
                       {{infoMessage}}
                    </td>
                </ng-container>
            <tr mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(userAuditList!=null && userAuditList.length==0 && isUserDataLoad)}"></tr>
          <!-- End- Footer for no record found -->
            </table>            
        </div>
        <div *ngIf="userAuditList.length" class="button-row m-t-20">           
            <button id="btn-submit-bottom" title="Submit" type="button" (click)="submtiAudits()" mat-flat-button color="primary" class="create"><img src="../../../assets/images/complete.svg"  alt="">Submit</button>
        </div>
    </div>  
</section>
<ng-template #confirmDialogTemplate>
    <app-confirm-dialog title="CONFIRMATION" successTitle="Yes" cancelTitle="No"
        message="All unsaved changes will be lost. Are you sure you want to exit?"
        (onConfirm)="onConfirm()" (onCancel)="onCancel()">
    </app-confirm-dialog>
</ng-template>