<div class="animated user-activate-popup" id="main-{{ alertConfirmDataModel.dialogId }}">
    <h2 mat-dialog-title>{{alertConfirmDataModel.title}}</h2>
    <mat-dialog-content class="mat-typography">
        <div mat-dialog-content class="text-center text-style">
            <p [innerHtml]="alertConfirmDataModel.message">
            </p>
        </div>

    </mat-dialog-content>
    <div mat-dialog-actions align="center">
        <button id="btn-confirm-dialog" type="button" mat-flat-button color="primary" class="m-l-10" cdkFocusInitial (click)="clickOnSave()" title="{{alertConfirmDataModel.okButtonName}}">{{alertConfirmDataModel.okButtonName}}</button>
        <button id="btn-cancel-dialog" type="button" mat-flat-button color="primary" (click)="clickOnCancel()" *ngIf="alertConfirmDataModel.isVisibleCancel" title="{{alertConfirmDataModel.cancelButtonName}}">{{alertConfirmDataModel.cancelButtonName}}</button>
    </div>
</div>