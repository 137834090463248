<app-header *ngIf="isLoginURL"></app-header>
<section class="" [ngClass]="{'wrapper': isLoginURL}">
    <app-leftnavmenu *ngIf="isLoginURL"></app-leftnavmenu>
    <router-outlet></router-outlet>
</section>
<app-footer *ngIf="isLoginURL"></app-footer>
<!-- <ngx-spinner *ngIf="!isLoginURL" bdColor="rgba(0,0,0,0)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner> -->
<ngx-spinner *ngIf="isLoginURL" bdColor="rgba(0,0,0,0.6)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
    <!-- <p style="color: white"> Loading... </p> -->
</ngx-spinner>
