<div class="animated user-activate-popup" id="main-{{ dialogId }}">
    <h2 mat-dialog-title>{{title}}</h2>
    <mat-dialog-content class="mat-typography">
        <div mat-dialog-content class="text-center text-style">
            <p>
                {{message}}
                <strong>{{ status }}</strong>
                <strong> {{ deviceName }}</strong>
            </p>
        </div>

    </mat-dialog-content>
    <div mat-dialog-actions align="center">
        <button id="btn-confirm-dialog" type="button" mat-flat-button color="primary" class="m-l-10" cdkFocusInitial (click)="clickOnSave()" title="{{successTitle}}" id="btn-{{successTitle}}">{{successTitle}}</button>
        <button id="btn-cancel-dialog" *ngIf="showCancelButton != 'false'" type="button" mat-flat-button color="primary" (click)="clickOnCancel()" title="{{cancelTitle}}" id="btn-{{cancelTitle}}">{{cancelTitle}}</button>
    </div>
</div>